<template>
  <div class="data-selecion-panel">
    <div class="row" v-if="myOptions.searchEnabled">
      <div class="form-group col-md-12">
        <div class="input-group">
          <input
            type="text"
            v-model="query"
            class="form-control"
            v-bind:placeholder="$t('search')"
            ref="query"
            :title="$t('titles.insert_search_text')"
          />
          <div
            class="input-group-addon btn"
            v-on:click.prevent.stop="resetQuery"
          >
            <i v-if="query" class="fa fa-close"></i>
            <i v-else class="fa fa-search"></i>
          </div>
        </div>
      </div>
    </div>
    <SearchableTable
      :searchEnabled="false"
      :fields="fields"
      :items="filteredList"
      :multiSelection="multiSelection"
      @select="onSelect"
      ref="stbl"
    >
      <!-- begin name -->
      <template #name="entry">
        <div class="cell-name" :title="title(entry.item)">
          <div class="cell-name-locker">
            <i
              class="fa"
              v-bind:class="{
                'fa-lock fa-disabled': entry.item.read_only,
                'fa-unlock ': !entry.item.read_only
              }"
              :title="
                entry.item.read_only
                  ? $t('titles.data_not_allows_writing')
                  : !entry.item.enabled
                  ? `${$tc('data')} ${$tc('disabled').toLowerCase()}`
                  : $t('titles.data_allows_writing')
              "
            ></i>
          </div>
          <div class="cell-name-text">
            <div>{{ entry.item.name.toUpperCase() }}</div>
            <!-- <div class="small" v-if="entry.item.name.toUpperCase()!=entry.item.description.toUpperCase()">{{ entry.item.description }}</div> -->
            <div class="small" v-if="entry.item.description">
              {{ entry.item.description }}
            </div>
          </div>
        </div>
      </template>
      <!-- end name -->

      <!-- begin value -->
      <template #value="entry">
        <div class="text-right column-value" :title="title(entry.item)">
          <DataValueDisplay
            class="display"
            :entry="entry.item"
            :zeroAsDash="zeroAsDash"
            :editor="true"
            @save="$emit('save', $event)"
            @editDataArray="$emit('editDataArray', $event)"
            :key="`${entry.item.device.id}${
              entry.item.pending_command || entry.item.pending_mapping_write
                ? 'p'
                : 'u'
            }`"
          />
        </div>
      </template>
      <!-- end value -->

      <!-- begin configure -->
      <template #configure="entry">
        <button
          type="button"
          class="btn btn-xs btn-default large-text"
          @click.stop.prevent="edit(entry.item)"
          :title="$t('hints.access_to_configuration_form')"
        >
          <i class="fa fa-wrench"></i>
        </button>
      </template>
      <!-- end configure -->
    </SearchableTable>
  </div>
</template>

<script>
import DataValueDisplay from "@/components/data-value-display.vue";
import SearchableTable from "@/components/searchable-table.vue";
import { defDataFields } from "@/components/control-sidebar/property-editors/detail-form-data.vue";

export default {
  name: "EquipmentDataSelectionPanel",
  components: {
    DataValueDisplay,
    SearchableTable
  },
  props: {
    panel: {
      type: Object,
      required: false,
      default: () => null
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    },
    selected: {
      type: Array,
      required: false,
      default: () => []
    },
    writableDataSelection: {
      type: Boolean,
      required: false,
      default: () => false
    },
    equipment: {
      type: Object,
      required: false,
      default: null
    },
    value: {
      type: Array
    },
    // fields: {
    //   type: Array,
    //   required: false,
    //   default: () => []
    // },
    widgetOptions: {
      type: Object,
      required: false,
      default: function () {
        return {};
      }
    },
    refreshTimerEnabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      all: false,
      selectedData: [],
      myOptions: {
        filter: "",
        multiSelection: true,
        searchEnabled: false,
        onItemClick: "select", // edit || select,
        refreshInterval: 30000,
        min_height: "350px",
        max_height: "auto",
        overflow_x: "hidden",
        overflow_y: "auto",
        zeroAsDash: false
      },
      sort_def: {
        column: "name",
        asc: true
      },
      query: "",
      overwritten: false,
      multiSelection: {
        key: "id",
        values: []
      }
    };
  },
  computed: {
    dashboardMode() {
      return this.$store.getters["dashboard/mode"];
    },
    canEdit() {
      return this.$can("manage", "DadoCadastro");
    },
    hasSelectableItems() {
      return this.filteredList.some((i) => this.isSelectable(i));
    },
    refreshInterval() {
      return (
        ("refreshInterval" in this.myOptions &&
          this.myOptions.refreshInterval) ||
        30000
      );
    },
    zeroAsDash() {
      return "zeroAsDash" in this.myOptions ? this.myOptions.zeroAsDash : false;
    },
    connectorBasedFilter() {
      let lst = this.equipmentDataList || [];
      // if (this.overwritten) return lst;
      let custom_view = this?.equipment?.portal_data?.custom_view || null;
      if (custom_view && lst.length) {
        // begin: make it compatible with previous version (devicename+dataname)
        let setReferenceId = function (list) {
          for (var i in list || []) {
            let data = lst.find((item) => {
              return item.device.name + "." + item.name == list[i];
            });
            if (data) {
              list[i] = data.reference_id;
            }
          }
        };
        if (custom_view.order_type == "manual")
          setReferenceId(custom_view.order || []);
        setReferenceId(custom_view.excluded || []);
        setReferenceId(custom_view.checked || []);
        // end
        if (custom_view.order_type == "column") {
          if (this.$refs.stbl) {
            lst = this.sort(lst, custom_view.order);
          }
        } else if (custom_view.order_type == "manual") {
          lst = lst.sort(
            (a, b) =>
              custom_view.order.indexOf(this.dataPath(a)) -
              custom_view.order.indexOf(this.dataPath(b))
          );
        }
        if ((custom_view.excluded || []).length) {
          lst = lst.filter(
            (data) => !custom_view.excluded.includes(this.dataPath(data))
          );
        }
      }
      return lst;
    },
    filteredList() {
      let self = this;
      var query = self.query;
      let lst = this.connectorBasedFilter;
      let device_id = self.$store.getters.deviceId;
      if (device_id) {
        lst = lst.filter(function (i) {
          return i.device.id == device_id;
        });
      }
      // if (!this?.panel?.options?.dataList?.length) {
      if (self.myOptions.filter == "historic") {
        lst = lst.filter(function (i) {
          return i.historico;
        });
      }
      // lst = this.connectorBasedFilter(lst);
      // }
      return lst.filter(function (item) {
        return self.$utils.queryStrAtr(query, item);
      });
    },
    dataList() {
      return (
        this.$store.getters["dashboard/dataList"] ||
        (this?.equipment?.id
          ? this.$store.getters["dashboard/dataListFrom"](this.equipment.id) ||
            []
          : [])
      );
    },
    equipmentDataList: function () {
      // filter from panel
      if (this?.panel?.options?.dataList?.length) {
        return this.dataList
          .filter((data) => {
            let ix = this.panel.options.dataList.findIndex(
              ({ data_id }) => data_id == data.id
            );
            if (ix >= 0) {
              let option = this.panel.options.dataList[ix];
              data._ix = ix;
              if (option.data_value_index) {
                data.portal_data = data.portal_data || {};
                data.portal_data.data_value_index = option.data_value_index;
              }
              return true;
            }
            return false;
          })
          .sort((a, b) => a._ix - b._ix);
      }

      // filter from connector
      return this.dataList.filter(({ clp_id }) =>
        this?.equipment?.id ? this.equipment.id == clp_id : true
      );
    },
    fieldsAsObject: function () {
      var self = this;
      var lst = (self.fields || []).map(function (i) {
        return [i.data_id, i];
      });
      if (lst & lst.length) {
        if ("fromEntries" in Object) {
          return Object.fromEntries(lst);
        } else {
          var lineCfg = {};
          for (var i in lst) {
            lineCfg[lst[i][0]] = lst[i][1];
          }
          return lineCfg;
        }
      }
      return null;
    },
    panelStyle() {
      let style = {};
      if (parseInt(this.screenId) >= 1000000000) return style;
      // if ((this?.panel?.style || {})["overflow-x"] == "hidden") {
      //   style["overflow-x"] = "hidden";
      // }
      if ((this?.panel?.style || {})["overflow-y"] == "hidden") {
        style["overflow-y"] = "hidden";
      }
      return style;
    },
    fields() {
      let lst = JSON.parse(
        JSON.stringify(this?.panel?.options?.fields || defDataFields())
      );
      lst = lst
        .filter(({ visible }) => visible)
        .map((f) => {
          if (f.name == "configure" || f.name == "notification") {
            f.sortable = false;
          } else if (f.name == "date_time") {
            f.parser = (item) => {
              return item?.current_value?.date_time
                ? new Date(item?.current_value?.date_time)
                : "";
            };
            f.format = (v, item) => {
              return item?.current_value?.date_time
                ? moment(item?.current_value?.date_time).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )
                : "-";
            };
            f.hint = (item) => this.title(item);
          } else if (f.name == "memory_type") {
            f.parser = (item) => {
              return item?.memory_type?.name && item.memory_size > 1
                ? `${item?.memory_type?.name} [${item.memory_size}]`
                : item?.memory_type?.name || "-";
            };
          } else if (f.name == "device_name") {
            f.parser = (item) => {
              return item?.device?.name || "-";
            };
          } else if (f.name == "unity_label") {
            f.parser = (item) => {
              const v = (item?.unity_label || "").replace(/s$/, "");
              return (v && this.$te(v) && this.$tc(v, 2)) || v;
            };
          } else {
            f.hint = (item) => this.title(item);
          }
          return f;
        });
      return lst;
    }
  },
  methods: {
    edit(data) {
      if (!data || this.dashboardMode === "editor" || !this.canEdit) return;
      let device = (data && data.device) || null;
      let connector = (device && device.connector) || null;
      if (connector) {
        this.$router.push(
          `/dashboard/edit/connector/${connector.id}/device/${device.id}/data/${data.id}`
        );
      }
    },
    dataPath(data) {
      let prefix = data?.device?.data_collector_device_id
        ? `${data.device.reference_id}.`
        : "";
      return `${prefix}${data.reference_id}`;
    },
    isSelectable(item) {
      if (this.writableDataSelection) {
        return (
          !item.read_only &&
          item.enabled &&
          (this.$root.$formatter.memorySize(item) == 1 ||
            this.$root.$formatter.dataValueCurrentIndex(item) >= 0)
        );
      }
      return true;
    },
    resetQuery() {
      let self = this;
      self.query = "";
      self.$nextTick(() => self.$refs.query.focus());
    },
    sort(lst, sort_def) {
      const _v = (attr, value) => {
        switch (attr) {
          case "value":
            return parseFloat(value);
          case "date_time":
            return new Date(value).getTime();
          default:
            return value;
        }
      };
      let asc = sort_def.asc;
      let attr = sort_def.column || "name";
      let ret = lst.sort(function (a, b) {
        if (attr in a && attr in b) {
          if (
            asc
              ? _v(attr, a[attr]) > _v(attr, b[attr])
              : _v(attr, b[attr]) > _v(attr, a[attr])
          )
            return 1;
          if (
            asc
              ? _v(attr, b[attr]) > _v(attr, a[attr])
              : _v(attr, a[attr]) > _v(attr, b[attr])
          )
            return -1;
        }
        return 0;
      });
      return ret;
    },
    onClick: function (item) {
      var self = this;
      if (!self.isSelectable(item)) {
        return;
      }
      if (!self.myOptions.multiSelection) {
        this.selectedData = [];
      }
      if (
        this.selectedData &&
        this.selectedData.length &&
        this.selectedData.indexOf(item.id) >= 0
      ) {
        this.selectedData.splice(this.selectedData.indexOf(item.id), 1);
      } else {
        this.selectedData.push(item.id);
      }
    },
    onSelect(entry) {
      if (Array.isArray(entry)) {
        this.multiSelection.values = (this.equipmentDataList || [])
          .filter(
            (item) => entry.indexOf(item.id) >= 0 && this.isSelectable(item)
          )
          .map(({ id }) => id);
      } else {
        if (!entry || !entry.id || !this.isSelectable(entry)) return;
        const ix = (this.multiSelection.values || []).findIndex(
          (id) => parseInt(entry.id) === parseInt(id)
        );
        if (ix == -1) this.multiSelection.values.push(entry.id);
        else this.multiSelection.values.splice(ix, 1);
      }
      this.$emit("input", this.multiSelection.values);
    },
    title(item) {
      return `${this.$tc("data", 1)}: (${item.id}) ${item.name}\n${this.$tc(
        "device",
        1
      )}: (${item.device.id}) ${item.device.name}\n${this.$t("memory_type")}: ${
        item.memory_type.name || ""
      }\n${this.$t("value")}: ${
        item.current_value ? item.current_value.value : ""
      }${
        item.identity_embedded_app
          ? "\n" +
            this.$t("identity_embedded_app") +
            ": " +
            item.identity_embedded_app
          : ""
      }`;
    }
  },
  beforeCreate() {
    var self = this;
    self.timer = null;
  },
  destroyed: function () {
    var self = this;
    if (self.timer) {
      clearInterval(self.timer);
      self.timer = null;
    }
  },
  mounted: function () {
    this.multiSelection.values = this.selected || [];
    var widgetOptions =
      (this.widgetOptions &&
        "options" in this.widgetOptions &&
        this.widgetOptions.options) ||
      null;
    if (widgetOptions) {
      // merge them both
      this.myOptions = { ...this.myOptions, ...widgetOptions };
    }
  }
};
</script>

<style scoped>
.table-container {
  overflow: auto;
  flex: 1;
}

/* .table-container > table {
  margin-bottom: 0;
} */

.data-selecion-panel {
  display: flex;
  flex-direction: column;
}

.selectable:hover {
  cursor: pointer;
  color: #10143e;
}
.fa-disabled {
  color: rgb(150 150 150);
}
.large-text {
  font-size: 2rem;
  padding-top: 2px;
}
.disabled *:not(:last-child) {
  color: #777;
  opacity: 0.8;
}

.table > tbody > tr > td {
  vertical-align: middle;
}

.table > tbody > tr > td > input[type="checkbox"] {
  vertical-align: middle;
}

.column-value {
  /* min-width: 150px;
  max-width: 300px; */
  padding: 0;
  vertical-align: middle;
}

.column-value > .display {
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-name {
  line-height: 1em;
  white-space: nowrap;
  margin: 10px 0px;
  font-size: 90%;
  vertical-align: middle;
}
.cell-name-locker {
  display: inline-block;
  vertical-align: middle;
  min-width: 24px;
}

.cell-name-text {
  display: inline-block;
  vertical-align: middle;
  /* font-size: 90%; */
}

.cell-timestamp {
  white-space: nowrap;
  font-size: 95%;
  vertical-align: middle;
}
.cell-config {
}
.input-group .form-control {
  z-index: 0 !important;
}

::v-deep section.searchable-table {
  overflow: auto;
}

::v-deep section.searchable-table > div > table > tbody > tr > td {
  min-height: 46px;
}

::v-deep section.searchable-table > div > table > tbody > tr.text-info {
  color: #333;
}
</style>
